// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --theme-color: white;
  --color1: #487353;
  --color2: #4B93BF;
  --color3: #425948;
  --color4: #9b9b9b;
  --text-color-inverse: white;
  --text-color: black;
  --navbar-shadow-color: #e5e5e5;
  --menu-mobile-background-color: #425948;
}

body {
  font-family: 'Montserrat', sans-serif;
  background-color: var(--theme-color);
  color: var(--text-color);
  padding-top: 100px;
  padding-left: 80px;
  padding-right: 80px;
  /* overflow-y: scroll; 
  height: 100vh; */
  
}

a {
  text-decoration: none;
  color: var(--text-color)
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,2BAA2B;EAC3B,mBAAmB;EACnB,8BAA8B;EAC9B,uCAAuC;AACzC;;AAEA;EACE,qCAAqC;EACrC,oCAAoC;EACpC,wBAAwB;EACxB,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB;kBACgB;;AAElB;;AAEA;EACE,qBAAqB;EACrB;AACF","sourcesContent":[":root {\n  --theme-color: white;\n  --color1: #487353;\n  --color2: #4B93BF;\n  --color3: #425948;\n  --color4: #9b9b9b;\n  --text-color-inverse: white;\n  --text-color: black;\n  --navbar-shadow-color: #e5e5e5;\n  --menu-mobile-background-color: #425948;\n}\n\nbody {\n  font-family: 'Montserrat', sans-serif;\n  background-color: var(--theme-color);\n  color: var(--text-color);\n  padding-top: 100px;\n  padding-left: 80px;\n  padding-right: 80px;\n  /* overflow-y: scroll; \n  height: 100vh; */\n  \n}\n\na {\n  text-decoration: none;\n  color: var(--text-color)\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
